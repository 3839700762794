/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-03-23",
    versionChannel: "nightly",
    buildDate: "2024-03-23T00:05:24.393Z",
    commitHash: "d70560cb8250d4dd6c34c812b4fbec027907faea",
};
